import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import { ThemeSwitch } from '@helpers/theme';
import HeadingStyles from '@stories/Components/Global/Typography/Heading.styles';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import { transparentize } from 'polished';
import styled from 'styled-components';
const Wrapper = styled.div `
  // Needed for the horizontal scroll table
  overflow: auto;
  line-height: normal;

  h1,
  h2 {
    ${HeadingStyles.H5Styles};
    ${ThemeSwitch({ groundforceColour: brand.primary.alt })}
    color: var(--theme);
  }

  p {
    ${ParagraphStyles.RegularStyles};
  }

  a {
    ${ThemeSwitch({ groundforceColour: brand.primary.alt })}
    color: var(--theme);
  }

  table {
    table-layout: fixed;
    min-width: 700px;
    width: 100%;

    thead {
      tr {
        background: ${brand.grey.grey35};
      }
    }

    tbody {
      tr {
        background-color: ${brand.grey.grey96};

        &:nth-child(odd) {
          ${ThemeSwitch({
    groundforceColour: 'rgba(102, 153, 0, 0.12)',
    tpaColour: transparentize(0.88, brand.tpa.base),
})}
          background-color: var(--theme);
        }
      }
    }

    th,
    td {
      padding: 12px 20px;
      width: 216px;

      @media ${from(Device.DesktopLarge)} {
        padding: 12px 34px;
      }
    }

    td {
      color: ${brand.black};

      border-right: 1px solid ${brand.grey.grey44};
      &:last-child {
        border-right: none;
      }
    }

    th {
      ${fonts.DaxPro.Bold};
      color: ${brand.white};
      font-size: 20px;
    }
  }

  iframe {
    min-height: 900px;
  }

  &[data-hidden='true'] {
    display: none;
  }
`;
export const WYSIWYGStyles = {
    Wrapper,
};
