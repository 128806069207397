import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import styled from 'styled-components';
import { WYSIWYGStyles } from '../../Shared.styles';
const Container = styled.div `
  width: 100%;
  height: max-content;
  max-height: 100%;

  padding: 20px 15px;
  background-color: ${brand.grey.grey96};
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${from(Device.TabletLarge)} {
    max-height: 55vh;
    height: 100%;
    padding: 35px 30px;
  }
`;
const Title = styled.div `
  ${fonts.DaxPro.Bold};
  color: ${brand.primary.alt};
  font-size: 22px;
`;
const TextContainer = styled.div `
  ${fonts.DaxPro.Regular};
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 32dvh;

  user-select: none;

  ${WYSIWYGStyles}
  font-size: 15px;
  line-height: 21px;
  white-space: pre-line;
  padding-right: 20px;

  @media ${until(Device.TabletLarge)} {
    max-height: 45dvh;
  }

  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar-track {
    background-color: ${brand.white};
  }

  &::-webkit-scrollbar {
    height: 6px;
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${brand.grey.grey89};
    border-radius: 0;
    cursor: pointer;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  a {
    word-break: break-word;
  }

  li {
    font-size: 14px;
    margin-bottom: 5px;
    padding-left: 26px;
    position: relative;
    list-style: none;

    &:before {
      content: '';
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: ${brand.primary.base};
      position: absolute;
      top: 0.5em;
      left: 0px;
    }
  }
`;
export const TermsAndConditionsStyles = {
    Container,
    Title,
    TextContainer,
};
