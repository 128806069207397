import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import styled from 'styled-components';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 17px;

  p {
    margin-bottom: 10px;
    color: ${brand.black};
  }

  label {
    ${fonts.DaxPro.Medium};
  }

  textarea {
    ${fonts.DaxPro.Regular};
    font-size: 17px;
    margin-bottom: 10px;
  }

  small {
    font-size: 13px;
  }
`;
const Heading = styled.h3 `
  color: ${brand.primary.alt};
  font-size: 30px;
  font-weight: bold;
`;
const SuccessText = styled.div `
  color: ${brand.black};
`;
const SolutionHeader = styled.p `
  margin-bottom: 5px;
`;
const ChosenSolution = styled.p `
  font-size: 20px;
  font-weight: bold;
  color: ${brand.primary.alt};
`;
const ButtonContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
`;
const ErrorText = styled.div `
  color: ${brand.red.base};
  ${fonts.DaxPro.Medium};
  
  p {
    color: ${brand.red.base};
  }
`;
export const GetQuoteStyles = {
    Container,
    SuccessText,
    Heading,
    SolutionHeader,
    ChosenSolution,
    ErrorText,
    ButtonContainer,
};
