import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import styled from 'styled-components';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 40px;
  max-width: 850px;
  width: 100%;

  ${() => FormStyles.FormRow} {
    @media ${from(Device.Tablet)} {
      position: relative;
      z-index: 1;
    }
  }

  @media ${from(Device.Tablet)} {
    position: relative;
    z-index: 1;
  }
`;
const Item = styled.div `
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 40px;

  @media ${from(Device.Tablet)} {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  h2 {
    font-size: 21px;
    text-align: left;
    margin-bottom: 18px;
  }

  ul {
    margin-bottom: 32px;
    padding: 0px;
    list-style-type: none;
    border: 1px solid ${brand.primary.light};
    list-style: disc;

    @media ${from(Device.Tablet)} {
      list-style-type: disc;
      padding-left: 18px;
      border: none;
    }

    li {
      color: ${brand.primary.light};
      padding: 5px 10px;
      border-bottom: 1px solid ${brand.primary.light};
      list-style-type: disc;

      @media ${from(Device.Tablet)} {
        padding: 0px;
        border-bottom: none;
      }

      &:before {
        display: none;
      }

      &:nth-child(even) {
        background-color: #e2f0d9;

        @media ${from(Device.Tablet)} {
          background: none;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      strong {
        margin-right: auto;

        @media ${from(Device.Tablet)} {
          margin-right: 0px;
        }

        &:after {
          @media ${from(Device.Tablet)} {
            content: ':';
            display: inline-block;
            margin-right: 4px;
          }
        }
      }

      span {
        color: #333333;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        ${fonts.DaxPro.Bold};

        @media ${from(Device.Tablet)} {
          display: inline-block;
        }
      }
    }
  }
`;
const Copy = styled.div `
  flex: 0 0 65%;
`;
const CopyInner = styled.div `
  padding: 15px;

  @media ${from(Device.Tablet)} {
    padding: 70px;
  }
`;
const Media = styled.div `
  flex: 0 0 35%;
  background-color: ${brand.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  picture {
    height: 100%;
  }

  img {
    flex: 0 0 auto;
    display: block;
    margin-left: auto;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
const MediaInner = styled.div `
  width: 100%;
  padding: 16px;
  flex: 1 0 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  display: block;

  @media ${from(Device.Tablet)} {
    padding: 0;
  }
`;
const CtaArea = styled.div `
  display: flex;
  justify-content: center;
  
  @media ${from(Device.Tablet)} {
    justify-content: flex-start;
  }
`;
const CtaAreaInner = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
export const SolutionsStyles = {
    Container,
    Item,
    Copy,
    CopyInner,
    Media,
    MediaInner,
    CtaArea,
    CtaAreaInner,
};
