import React from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { SolutionsStyles as S } from './Solutions.styles';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import YSButton, { YSLinkButton } from '../../Shared/YSButton/YSButton';
const Solutions = () => {
    const { state, dispatch } = useYSPContext();
    if (state.results === undefined) {
        return null;
    }
    const handleSetModal = (solution) => {
        dispatch({
            type: 'set-modal',
            payload: {
                fullWidth: true,
                title: '',
                size: 'normal',
                getQuoteOptions: {
                    chosenSolution: solution,
                    content: state.labels['quotemodal_content'],
                    heading: state.labels['quotemodal_heading'],
                    success: state.labels['quotemodal_success'],
                    startDateRequired: state.labels['quotemodal_start_date_required'],
                    startDateHigherThanEndDate: state.labels['quotemodal_start_date_higher_than_end_date'],
                    startDateEarlierThanToday: state.labels['quotemodal_start_date_earlier_than_today'],
                },
            },
        });
    };
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement(SharedStyles.CenterText, { dangerouslySetInnerHTML: { __html: state.results.responseMessage } })),
        React.createElement(S.Container, null, state.results.solutions.map((x) => (React.createElement(S.Item, { key: x.url },
            React.createElement(S.Copy, null,
                React.createElement(S.CopyInner, null,
                    React.createElement("h2", null, x.displayName),
                    React.createElement("ul", null, Object.keys(x.tableData).map((key) => (React.createElement("li", { key: key },
                        React.createElement("span", null,
                            React.createElement("strong", null, key),
                            x.tableData[key]))))),
                    React.createElement(S.CtaArea, null,
                        React.createElement(S.CtaAreaInner, null,
                            React.createElement(YSLinkButton, { fill: "outline", target: "_blank", href: x.url }, "Download Solution"),
                            React.createElement(YSButton, { fill: "outline", colour: "orange", onClick: () => handleSetModal(x.displayName) }, "Request a Quote"))))),
            React.createElement(S.Media, null,
                React.createElement(S.MediaInner, null,
                    React.createElement("picture", null,
                        React.createElement("source", { media: "(min-width: 996px)", srcSet: x.desktopImage }),
                        React.createElement("img", { src: x.desktopImage, srcSet: x.mobileImage })))))))),
        state.authenticatedUser === undefined && (React.createElement(FormStyles.Form, null,
            React.createElement(SharedStyles.CenterText, null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: state.results.technicalMessage } }),
                React.createElement("br", null),
                React.createElement(YSLinkButton, { center: true, href: state.technicalLibraryUrl }, "Create an Account"))))));
};
export default Solutions;
