import { Cloudinary } from '@cloudinary/base';
import { format, quality } from '@cloudinary/base/actions/delivery';
import { fit } from '@cloudinary/base/actions/resize';
import { audioCodec } from '@cloudinary/base/actions/transcode';
import { auto as fAuto } from '@cloudinary/base/qualifiers/format';
import { auto as qAuto } from '@cloudinary/base/qualifiers/quality';
const cl = new Cloudinary({
    cloud: {
        cloudName: 'vp-groundforce',
    },
    url: {
        secure: true,
    },
});
export function imageUrl(publicId, rawTransformation) {
    if (!publicId) {
        return '';
    }
    const image = cl.image(publicId);
    // Apply raw transformation.
    if (rawTransformation) {
        image.addTransformation(rawTransformation);
    }
    // Ensure we're specifying a format...
    if (containsFormatTransformation(rawTransformation)) {
        return image.toURL();
    }
    // ...otherwise serve with "f_auto" for WebP support.
    return image.delivery(format(fAuto())).delivery(quality(qAuto())).toURL();
}
export function videoUrl(publicId, options = {}) {
    if (!publicId) {
        return '';
    }
    let video = cl.video(publicId);
    // Apply resize.
    if (options.aspectRatio || options.width) {
        let resize = fit();
        if (options.aspectRatio) {
            resize = resize.aspectRatio(options.aspectRatio);
        }
        if (options.width) {
            resize = resize.width(options.width);
        }
        video = video.resize(resize);
    }
    // Apply transcale (remove audio).
    if (options.audioCodec) {
        video = video.transcode(audioCodec(options.audioCodec));
    }
    // Apply format.
    if (options.format) {
        video = video.delivery(format(options.format));
    }
    return video.toURL();
}
// eslint-disable-next-line no-useless-escape
const FormatRegEx = /(\/|\,)(f_.*?)(\/|\,)/;
function containsFormatTransformation(transformation) {
    return !!transformation && FormatRegEx.test(transformation);
}
export function extractPublicId(url) {
    const publicId = url.split('/groundforce').pop();
    if (!publicId) {
        throw new Error('Failed to extract public ID from URL');
    }
    return `/groundforce${publicId}`;
}
