import ApiService from '@core/api/services/ApiService';
import React, { useState } from 'react';
import { useYSPContext } from '../../../Context/YSContext';
import { Button } from '../../StepFooter/StepFooter.styles';
import { GetQuoteStyles as S } from './GetQuote.styles';
const GetQuote = (props) => {
    const [comment, setComment] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState();
    const { state } = useYSPContext();
    const [error, setError] = useState();
    const handleSubmit = async () => {
        if (!startDate) {
            if (props.labels['quote.modal.start-date-required']) {
                setError(`<p>${props.labels['quote.modal.start-date-required']}</p>`);
            }
            return;
        }
        if (startDate) {
            if (endDate) {
                if (new Date(startDate) > new Date(endDate)) {
                    setError(`<p>${props.labels['quote.modal.start-date-higher-than-end-date']}</p>`);
                    return;
                }
            }
            if (new Date(startDate) < new Date()) {
                setError(`<p>${props.labels['quote.modal.start-date-earlier-than-today']}</p>`);
                return;
            }
        }
        setError(undefined);
        setSubmitting(true);
        const response = await ApiService.request({
            controller: 'ISM',
            slug: 'quote',
            method: 'POST',
            isMultipartFormData: true,
            params: {
                chosenSolution: props.chosenSolution,
                id: state.results?.questionnaireID,
                comment,
                startDate,
                endDate,
            },
        });
        setSuccess(response.result);
        if (!response.result) {
            setError(response.status.message);
        }
        setSubmitting(false);
    };
    return (React.createElement(S.Container, null, success === true ? (React.createElement(S.SuccessText, { dangerouslySetInnerHTML: { __html: props.labels['quote.modal.success'] } })) : (React.createElement(React.Fragment, null,
        React.createElement(S.Heading, null, props.labels['quote.modal.heading']),
        React.createElement(S.SolutionHeader, null, "Your chosen solution:"),
        React.createElement(S.ChosenSolution, null, props.chosenSolution),
        React.createElement("div", { dangerouslySetInnerHTML: { __html: props.labels['quote.modal.content'] } }),
        React.createElement("label", { htmlFor: "getquote-startdate" }, "Start Date*:"),
        React.createElement("input", { type: "date", value: startDate, onChange: (e) => {
                setStartDate(e.currentTarget.value);
            }, id: "getquote-startdate" }),
        React.createElement("br", null),
        React.createElement("label", { htmlFor: "getquote-enddate" }, "End Date:"),
        React.createElement("input", { type: "date", value: endDate, onChange: (e) => setEndDate(e.currentTarget.value), id: "getquote-enddate" }),
        React.createElement("br", null),
        React.createElement("label", { htmlFor: "getquote-comment" }, "Comments:"),
        React.createElement("textarea", { value: comment, onChange: (e) => setComment(e.currentTarget.value), id: "getquote-comment", rows: 4 }),
        React.createElement(S.ButtonContainer, null,
            React.createElement(Button, { disabled: submitting, onClick: () => handleSubmit() }, "Submit"),
            error && React.createElement(S.ErrorText, { dangerouslySetInnerHTML: { __html: error } }))))));
};
export default GetQuote;
